import React from 'react'
import Layout from 'src/components/layout/layout'
import SEO from 'src/components/seo'
import InviteDirector from 'src/components/tournament-director-invite/tournament-director-invite'
import { usePrivateRoute } from 'src/utils/auth'

const TournamentDirectorInvite = () => {
  usePrivateRoute()
  return (
    <Layout>
      <SEO title="Invite Tournament Director" />
      <InviteDirector />
    </Layout>
  )
}

export default TournamentDirectorInvite
